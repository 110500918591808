<template>
  
      <v-card
        class="mx-auto my-12"
        max-width="374">

        <v-img
          height="180"
          src="@/assets/images/banner.jpg">
        </v-img>

        <v-card-text v-if="!loading">
          
          <v-row>
            <v-col align="center" cols="12">
              {{$i18n.t('ConfirmaNovaSenha')}}
              <br>
              <b>{{email}}</b>
            </v-col>
          </v-row>        
        </v-card-text>

        <div class="text-center mg-10" v-if="loading">
          <v-progress-circular
            indeterminate
            color="primary">
          </v-progress-circular>
        </div>

        <v-card-actions v-if="!loading">

          <div class="w100">
            <v-row>
              <v-col align="center" cols="12">
                <v-btn
                  color="primary"
                  elevation="2"
                  @click="confirma">
                  {{$i18n.t('Confirmar')}}
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col align="left" lg="6" cols="6" sm="6">
                <div
                  class="link-home"
                  @click="voltaParaLogin">
                  {{$i18n.t('VoltaParaLogin')}}
                </div>
              </v-col>

              <v-col align="right" cols="6" lg="6" sm="6">
                <div
                  class="link-home"
                  @click="acessaSemCredencial">
                  {{$i18n.t('SemCredencial')}}
                </div>
              </v-col>
            </v-row>
          </div>
          
        </v-card-actions>
        
      </v-card>
  
</template>

<script>
  export default {
    data: () => ({
      loading: false,
      email: '',
      codigo: ''
    }),
    methods: {
      confirma() {
        this.loading = true;
        this.$http.get(`/login/confirma_recuperacao?codigo=${this.codigo}`)
          .then((res) => {
            if (res.data.sucesso) {
              this.$msg.sucesso(res.data.mensagem);
              this.voltaParaLogin();
            }
            else
              this.$msg.erro(res.data.mensagem);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      acessaSemCredencial() {
        this.$router.push('/rastrear');
      },
      voltaParaLogin() {
        this.$router.push('/');
      }
    },
    mounted() {
      this.email = this.$route.query.e;
      this.codigo = this.$route.query.c;
    }
  }
</script>